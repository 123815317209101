.tabs {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    &__tab {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 40px;
        margin: 4px 6px;
        width: 100%;
        max-width: 329px;
        height: 57px;
        background: rgba(255, 255, 255, 0.3);
        border: 1px solid #2297FC;
        box-sizing: border-box;
        border-radius: 5px;
        font-family: Nunito Sans, sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        color: #2297FC;
        outline: none;
    }
}

.briefcase::before {
    content: "";
    display: inline-block;
    background: url("../assets/icons/briefcase.svg") center no-repeat;
    background-size: contain;
    width: 24px;
    height: 24px;
}

.tabs__tab--active.briefcase::before {
    background: url("../assets/icons/briefcase_active.svg") center no-repeat;
    background-size: contain;
}

.targeting-tab::before {
    content: "";
    display: inline-block;
    background: url("../assets/icons/targeting_tab.svg") center no-repeat;
    background-size: contain;
    width: 24px;
    height: 24px;
}

.tabs__tab--active.targeting-tab::before {
    background: url("../assets/icons/targeting_active.svg") center no-repeat;
    background-size: contain;
}

.library::before {
    content: "";
    display: inline-block;
    background: url("../assets/icons/library.svg") center no-repeat;
    background-size: contain;
    width: 24px;
    height: 24px;
}

.tabs__tab--active.library::before {
    background: url("../assets/icons/library_active.svg") center no-repeat;
    background-size: contain;
}

.tracking::before {
    content: "";
    display: block;
    background: url("../assets/icons/tracking.svg") center no-repeat;
    background-size: contain;
    width: 24px;
    height: 24px;
}

.tabs__tab--active.tracking::before {
    background: url("../assets/icons/tracking_active.svg") center no-repeat;
    background-size: contain;
}

.tab-content {
    display: none;

    &--active {
        display: block;

        &:after {
            content: '';
            display: block;
            clear: both;
        }
    }
}

.about__tabs {
    margin: 65px 0 0 0;
    width: 100%;

    .tabs {
        justify-content: center;
        white-space: nowrap;
        flex-wrap: nowrap !important;
        flex-direction: row;

        &__tab {
            font-size: 13px;
            padding: 0 20px;
            margin: 0;
            border-radius: 0;
            justify-content: center;
            border: none;
            border-bottom: 1px #C4C4C4 solid;
            color: #C4C4C4;

            &--active {
                background: none;
                color: #2297FC;
                border-bottom: 1px #2297fc solid;
                box-shadow: inset 0 -3px 0 0 #2297fc;
            }
        }
    }
}

@media only screen and (max-width: 767px) {
    .key-features__tabs .tabs {
        position: absolute;

        &__tab {
            width: 100%;
            min-width: 329px;
            display: none;
            justify-content: left;
            background-color: #ffffff;
            z-index: 3;

            &::before {
                margin-right: 16px;
            }

            &--active {
                display: flex;
                order: -1;

                &::after {
                    content: "";
                    position: absolute;
                    right: 35px;
                    display: block;
                    background: url("../assets/icons/chevron.svg") center no-repeat;
                    width: 24px;
                    height: 24px;
                }
            }
        }
    }

    .tabs:hover .tabs__tab {
        display: flex;
    }

    .key-features__tabs .tabs:hover .tabs__tab--active {
        background: #EDEDED;
        border: 1px solid rgba(255, 255, 255, 0.5);
        color: white;
    }

    .key-features__tabs .tabs:hover .tabs__tab--active::after {
        content: "";
        display: block;
        background: url("../assets/icons/chevron-down.svg") center no-repeat;
        background-size: contain;
        width: 24px;
        height: 24px;
    }
}

@media only screen and (min-width: 768px) {
    .tabs {
        width: 100%;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: center;

        &__tab {
            padding: 0 51px;
            max-width: 334px;
            font-weight: bold;
            font-size: 18px !important;
            line-height: 25px;
            height: 68px;
            margin-bottom: 12px;
            border: 1px solid #E5E5E5;
            justify-content: left;

            &::before {
                margin-right: 12px;
                width: 32px;
                height: 32px;
            }

            &--active {
                background: #2297FC;
                color: #FFFFFF;
            }
        }
    }
}

@media only screen and (min-width: 1280px) {
    .tabs {

        &__tab {
            width: 270px;
            padding: 0 18px;
            justify-content: left;

            &::before {
                margin-right: 12px;
            }
        }
    }

    .tab-content--active:not(.about-tab-content) {
        display: flex;
        justify-content: space-between;
    }
}