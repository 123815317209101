.benefits {
    margin-top: 60px;
    padding: 117px 16px 0;
    background: url("../assets/backgrounds/benefits-background.svg") no-repeat;
    background-size: 100% auto;

    &__heading {
        line-height: 44px;
    }

    &__list {
        padding: 0 16px;
        list-style: none;
    }

    &__item {
        margin: 54px 0;
    }

    &__text {
        margin-bottom: 20px;
    }
    &__subheading {
        color: #8C8C8C;
        margin-top: 20px;
    }
}

.ctr::before {
    content: "";
    display: block;
    margin: 35px auto;
    background: url("../assets/icons/ctr.svg") no-repeat;
    background-size: contain;
    width: 123.85px;
    height: 81.56px;
}

.reach::before {
    content: "";
    display: block;
    margin: 35px auto;
    background: url("../assets/icons/reach.svg") no-repeat;
    background-size: contain;
    width: 122.69px;
    height: 81.4px;
}

.simple::before {
    content: "";
    display: block;
    margin: 35px auto;
    background: url("../assets/icons/simple.svg") no-repeat;
    background-size: contain;
    width: 123.85px;
    height: 81.56px;
}

.instant::before {
    content: "";
    display: block;
    margin: 35px auto;
    background: url("../assets/icons/instant.svg") no-repeat;
    background-size: contain;
    width: 126px;
    height: 84.27px;
}

.real::before {
    content: "";
    display: block;
    margin: 35px auto;
    background: url("../assets/icons/real.svg") no-repeat;
    background-size: contain;
    width: 126px;
    height: 84.27px;
}

.low::before {
    content: "";
    display: block;
    margin: 35px auto;
    background: url("../assets/icons/low.svg") no-repeat;
    background-size: contain;
    width: 125.98px;
    height: 81.77px;
}

@media only screen and (min-width: 768px) {
    .benefits {
        margin-top: 35px;
        background: url("../assets/backgrounds/benefits-background-tablet.svg") no-repeat;
        background-size: 100%;
        padding: 165px 16px 0;

        &__list {
            margin: 53px auto 13px;
            display: flex;
            flex-wrap: wrap;
            padding: 0;
            justify-content: center;
            max-width: 800px;
        }

        &__item {
            margin: 0 0 0;
            padding: 15px 25px 7px;
            display: block;
            width: 299px;

            &:hover {
                box-shadow: 2px 2px 24px rgba(11, 0, 77, 0.08);
                border-radius: 6px 6px 8px 8px;
                transition: 0.3s;
            }
        }

        &__text {
            text-align: left;
            padding: 0;
        }
    }

    .heading--benefits {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        align-items: center;
        margin-bottom: 23px;
    }

    .ctr::before,
    .reach::before,
    .simple::before,
    .instant::before,
    .real::before,
    .low::before
    {
        display: block;
        width: 92px;
        height: 62px;
        margin: 0 16px 0 0;
    }
}

@media only screen and (min-width: 1280px) {
    .benefits {
        padding-top: 0;
        margin-top: 40px;
        background: none;

        &__heading {
            font-size: 36px;
            line-height: 56px;
            margin-bottom: 0;
        }

        &__subheading {

        }
        &__list {
            position: relative;
            z-index: 2;
            max-width: 1110px;
            margin: 48px auto 13px;
        }

        &__item {
            margin-bottom: 17px;
            padding: 15px 25px 7px;
            width: 299px;
        }
    }
}