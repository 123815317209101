.benefits-publishers {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -85px;

    &__heading {
        font-weight: normal;
        font-size: 32px;
        line-height: 48px;
        width: 270px;
        margin-bottom: 8px;
    }

    &__text {
        margin-top: 0;
        text-align: center;
        font-weight: normal;
        font-size: 26px;
        line-height: 38px;
        color: #8C8C8C;
        width: 270px;
    }

    &__list {
        margin-top: 32px;
        padding-left: 0;
        list-style: none;
    }
}

.pub-benefit {
    margin-bottom: 58px;

    &::before {
        content: "";
        display: block;
        width: 122px;
        height: 81px;
        margin: 0 auto;
    }

    &--pricing::before {
        background: url("../assets/icons/pub_benefit_pricing.svg") no-repeat center top;
    }

    &--safe::before {
        background: url("../assets/icons/pub_benefit_safe.svg") no-repeat center top;
    }

    &--setup::before {
        background: url("../assets/icons/pub_benefit_setup.svg") no-repeat center top;
    }

    &--monetization::before {
        background: url("../assets/icons/pub_benefit_monetization.svg") no-repeat center top;
    }

    &--withdrawal::before {
        background: url("../assets/icons/pub_benefit_withdrawal.svg") no-repeat center top;
    }

    &__heading {
        margin-top: 34px;
        margin-bottom: 18px;
        font-weight: normal;
        font-size: 20px;
        line-height: 26px;
    }

    &__text {
        padding: 0 24px;
        text-align: center;
    }
}

@media only screen and (min-width: 768px) {
    .benefits-publishers {
        margin-top: 35px;
        padding-bottom: 25px;

        &__heading {
            font-size: 44px;
            line-height: 57px;
            width: 100%;
        }

        &__text {
            font-size: 32px;
            line-height: 56px;
            width: 100%;
        }

        &__list {
            width: 768px;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: left;
            margin-top: 45px;
            padding-left: 80px;
        }
    }

    .pub-benefit {
        width: 344px;
        margin-right: 8px;
        margin-bottom: 42px;

        &:last-child {
            flex-basis: 700px;
        }

        &::before {
            width: 95px;
            height: 64px;
            background-size: contain;
            float: left;
            margin-right: 14px;
        }

        &__heading {
            text-align: left;
            font-size: 18px;
            line-height: 26px;
            margin-top: 19px;
        }

        &__text {
            margin-top: 42px;
            padding-left: 0;
            text-align: left;
        }
    }
}

@media only screen and (min-width: 1280px) {
    .benefits-publishers {
        margin-top: -25px;
        padding-bottom: 10px;

        &__heading {
            font-size: 48px;
            line-height: 57px;
            margin-bottom: 12px;
        }

        &__text {
            font-size: 36px;
            line-height: 56px;
        }

        &__list {
            width: 1200px;
            padding: 0;
            justify-content: center;
            margin-top: 25px;
        }
    }

    .pub-benefit {
        margin-right: 0;
        width: 380px;
        margin-bottom: 43px;
        transition: 0.3s;

        &:hover {
            box-shadow: 2px 2px 24px rgba(11, 0, 77, 0.08);
            border-radius: 6px 6px 8px 8px;
        }

        &:last-child {
            flex-basis: auto;
        }

        &::before {
            float: none;
            margin: 20px auto 0;
        }

        &__heading {
            margin-top: 30px;
            text-align: center;
        }

        &__text {
            text-align: center;
            margin-top: 0;
            padding: 0 16px;
        }
    }
}