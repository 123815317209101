.text {
  font-family: Nunito Sans, Nunito, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 31px;
  padding: 0 16px;
}

.text--centered {
  text-align: center;
}

.text--statistics {
  font-weight: bold;
  font-size: 60px;
  line-height: 82px;
  color: #2297FC;
  margin: 0;
}

.text--normal {
  font-style: normal;
  font-weight: 300;
}

.text--description {
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  margin: -3px 0 0 4px;
}

.text--light {
  color: #8C8C8C;
}

@media only screen and (min-width: 768px) {
  .text--statistics {
    font-size: 80px;
    line-height: 108px;
    padding: 0 24px;
  }

  .text--description {
    padding: 0 24px;
    margin: -7px 4px 0;
  }
}