.steps {
    background: url("../assets/backgrounds/steps_background_mobile.svg") center bottom no-repeat;
    padding: 294px 0 50px;
    margin-top: -174px;

    &__heading {
        font-size: 26px;
        line-height: 38px;
        color: #FFFFFF;
    }

    &__list {
        margin-top: 60px;
        padding-left: 0;
        list-style: none;
    }
}

.step {
    margin-bottom: 60px;

    &::before {
        content: "";
        display: block;
        margin: 0 auto;
        width: 140px;
        height: 140px;
    }

    &--account::before {
        background: url("../assets/icons/step_account.svg");
    }

    &--tag::before {
        background: url("../assets/icons/step_tag.svg");
    }

    &--result::before {
        background: url("../assets/icons/step_result.svg");
    }

    &__heading {
        margin-top: 32px;
        font-size: 20px;
        line-height: 26px;
        color: #FFFFFF;
    }

    &__text {
        text-align: center;
        color: #FFFFFF;
    }
}

@media only screen and (min-width: 768px) {
    .steps {
        margin-top: 0;
        padding-top: 190px;
        padding-bottom: 88px;
        background: url("../assets/backgrounds/steps_background_tablet.svg") center bottom no-repeat;

        &__heading {
            font-size: 30px;
            line-height: 48px;
            width: 453px;
            margin: 0 auto;
        }

        &__list {
            width: 688px;
            margin: 80px auto 0;
        }
    }

    .step {
        margin-bottom: 55px;

        &::before {
            width: 120px;
            height: 120px;
            background-size: contain;
            float: left;
            margin-right: 32px;
        }

        &__heading {
            font-size: 18px;
            line-height: 32px;
            text-align: left;
            padding-top: 25px;
            margin-bottom: 9px;
        }

        &__text {
            margin-top: 9px;
            text-align: left;
        }
    }
}

@media only screen and (min-width: 1280px) {
    .steps {
        background: url("../assets/backgrounds/steps_background_desktop.svg") center bottom no-repeat;
        padding-top: 308px;
        padding-bottom: 60px;

        &__heading {
            font-size: 36px;
            line-height: 56px;
            width: 100%;
        }

        &__list {
            display: flex;
            justify-content: space-between;
            width: 1100px;
        }
    }

    .step {
        max-width: 350px;

        &::before {
            float: none;
            width: 104px;
            height: 104px;
            margin: 0 auto;
        }

        &--account:hover::before {
            background: url("../assets/icons/step_account_hovered.svg");
        }

        &--tag:hover::before {
            background: url("../assets/icons/step_tag_hovered.svg");
        }

        &--result:hover::before {
            background: url("../assets/icons/step_result_hovered.svg");
        }

        &__heading {
            margin-top: 7px;
            text-align: center;
        }

        &__text {
            margin-top: 19px;
            text-align: center;
            width: 282px;
        }
    }
}