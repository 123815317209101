.card {
    width: 328px;
    margin: 0 auto 16px;
    box-shadow: 2px 2px 24px rgba(11, 0, 77, 0.08);
    border-radius: 6px 6px 8px 8px;
    padding-bottom: 60px;
    background-color: #FFFFFF;

    .text--green {
        color: #6CF089;
    }

    &:before {
        content: "";
        width: 100%;
        height: 6px;
        display: block;
        background: linear-gradient(3.77deg, #32A9FF 0%, #1CD6FF 100%), #FFFFFF;
        border-radius: 8px 8px 0 0;
    }

    &__list {
        margin: 24px 0 0 32px;
        list-style-image: url("../assets/icons/done-point.svg");
    }

    &__list-item {
        margin: 6px 0;
        padding-left: 5px;
    }

    &__payment-list {
        list-style: none;
        margin-top: -15px;
        padding-left: 83px;
        margin-bottom: 9px;
    }

    &__payment-item {
        display: flex;
        align-items: center;
    }
    &__item {
        max-width: 255px;
        border: 1px solid #E5E5E5;
        box-sizing: border-box;
        border-radius: 5px;
        margin: 30px auto 70px;
        padding: 0 0 20px;
        min-height: 150px;

        &:last-child {
            margin: 0 auto;
        }
    }
}

.targeting:before {
    content: '';
    margin: 54px auto 32px;
    display: block;
    background: url("../assets/icons/targeting.svg") center no-repeat;
    background-size: contain;
    width: 123px;
    height: 79.47px;
}

.pricing:before {
    content: '';
    margin: 54px auto 32px;
    display: block;
    background: url("../assets/icons/pricing.svg") center no-repeat;
    background-size: contain;
    width: 123px;
    height: 84.25px;
}

.payment:before {
    content: '';
    margin: 54px auto 32px;
    display: block;
    background: url("../assets/icons/payment.svg") center no-repeat;
    background-size: contain;
    width: 123px;
    height: 84.25px;
}

.pricing-type {
    margin: 0 auto;
    width: 232px;
    height: 52px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__item {
        font-family: Nunito Sans, sans-serif;
        font-style: normal;
        padding: 10px 16px 10px 18px;
        font-weight: bold;
        font-size: 18px;
        line-height: 26px;
        color: #6CF089;
        background: rgba(108, 240, 137, 0.3);
        border-radius: 8px;
        margin-right: 5px;
    }

    &__text {
        font-family: Nunito Sans, sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 26px;
        margin: 0 0 0 15px;
    }

    &__tag {
        font-family: Nunito Sans, sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 32px;
        line-height: 26px;
        color: #6CF089;
        margin-left: 48px;
        margin-top: 38px;
        margin-bottom: -5px;

        &--text {
            font-weight: normal;
            font-size: 16px;
            padding-left: 6px;
        }
    }
}

.wire:before {
    content: '';
    display: inline-block;
    background: url("../assets/icons/wire.svg") center no-repeat;
    width: 22px;
    height: 24.75px;
}

.visa:before {
    content: '';
    display: inline-block;
    background: url("../assets/icons/visa.svg") center no-repeat;
    width: 22px;
    height: 25px;
}

.capitalist:before {
    content: '';
    display: inline-block;
    background: url("../assets/icons/capitalist.svg") center no-repeat;
    width: 22px;
    height: 22.5px;
}

.paxum:before {
    content: '';
    display: inline-block;
    background: url("../assets/icons/paxum.svg") center no-repeat;
    width: 24px;
    height: 8px;
}
.payoneer:before {
    content: '';
    display: inline-block;
    background: url("../assets/icons/payoneer.svg") center no-repeat;
    width: 29px;
    height: 11px;
}
.paypal:before {
    content: '';
    display: inline-block;
    background: url("../assets/icons/paypal.svg") center no-repeat;
    width: 16px;
    height: 19px;
}



@media only screen and (min-width: 768px) {
    .card {
        width: 454px;
        padding-bottom: 72px;
        margin-bottom: 31px;

        &__list {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            margin: 38px 0 0 106px;
            flex-direction: column;
        }

        &__list-item {
            min-width: 160px;
        }

        &__payment-list {
            margin-top: 26px;
            padding-left: 157px;
            margin-bottom: 17px;
        }
    }

    .pricing-type {
        margin: 40px auto;

        &__item {
            font-weight: bold;
            font-size: 22px;
            line-height: 26px;
        }

        &__tag {
            font-weight: 700;
            font-size: 38px;
            line-height: 26px;
            margin-left: 34px;

            &--text {
                font-size: 16px;
            }
        }
    }

    .targeting:before,
    .pricing:before,
    .payment:before {
        width: 140px;
        height: 96px;
        margin: 70px auto 39px;
    }
}

@media only screen and (min-width: 1280px) {
    .card {
        width: 350px;
        margin: 0;
        padding-bottom: 52px;

        &__list {
            margin: 19px 0 0 55px;
        }

        &__list-item {
            margin: 3px 0;
            padding-left: 5px;
        }

        &__payment-list {
            margin-top: 30px;
            padding-left: 92px;
        }

        &__payment-text {
            margin: 14px 0;
        }
    }

    .pricing-type {
        margin: 35px auto 39px;

        &__item {
            font-size: 18px;
            line-height: 26px;
        }

        &__tag {
            font-size: 32px;
            line-height: 26px;
            margin-left: 60px;

            &--text {
                font-size: 16px;
                line-height: 26px;
            }
        }
    }

    .targeting:before,
    .pricing:before,
    .payment:before {
        width: 96px;
        height: 64px;
        margin: 55px auto 31px;
    }
}