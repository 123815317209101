:target::before {
    content: "";
    display: block;
    height: 140px;
    margin: -60px 0 0;
}

.legal {
    padding-top: 100px;
    padding-bottom: 80px;
    margin: 0 auto;

    &__heading {
        font-weight: normal;
        font-size: 30px;
        line-height: 48px;
        text-align: center;
    }

    &__list {
        margin: 72px 0 70px;
        padding-left: 24px;
        font-weight: bold;
    }

    &__list-item {
        line-height: 37px;
        color: #000000;
    }

    &__article {
        padding-top: 20px;
    }

    &__subheading {
        font-size: 16px;
        line-height: 31px;
        letter-spacing: 0.1em;
        margin: 0 0 5px;
    }

    &__text {
        margin: 0 0 6px;
        padding: 0;

        &--accent {
            font-weight: bold;
            font-size: 18px;
            line-height: 30px;
        }
    }
}

@media only screen and (min-width: 768px) {
    .legal {
        padding: 160px 40px 80px;

        &__heading {
            font-size: 44px;
            line-height: 52px;
        }

        &__list {
            margin: 63px 0 70px;
            padding-left: 24px;
            font-weight: bold;
        }

        &__article {
            padding-top: 20px;
        }
    }
}

@media only screen and (min-width: 1280px) {
    .legal {
        max-width: 920px;
        padding: 190px 0 140px;

        &__heading {
            font-size: 48px;
            line-height: 56px;
        }

        &__list {
            margin: 70px 0 33px;
            padding-left: 24px;
            font-weight: bold;
        }

        &__text {

            &--accent {
                font-size: 16px;
                line-height: 30px;
            }
        }

        &__article {
            padding-top: 35px;
        }
    }
}