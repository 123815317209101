.about-onclick {
    &__laptop {
        width: 240px;
        height: 392px;
        overflow: hidden;
        display: block;
        margin: 30px 0 0 0;
        position: absolute;
        right: 0;
    }
    &__phone {
        margin: 105px 170px 10px 0;
        display: block;
        z-index: 2;
        position: relative;
    }
}
.about-ipp {
    &__laptop {
        position: absolute;
        display: block;
        width: 388px;
        overflow: hidden;
        right: 0;
        margin: -25px 0 0 0;
        height: 470px;
    }
    &__phone {
        display: block;
        position: relative;
        z-index: 2;
        margin: 205px 0 -20px 0;
        left: -135px;
    }
}
.about-push {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .cta {
        margin: 20px 0 0 0;
        padding: 18px;
        font-size: 18px;
        position: relative;
    }
    &__heading {
        margin: 0;
        padding: 0 48px;
    }

    &__laptop {
        margin-top: 61px;
        margin-left: -265px;
        height: 458px;
        width: 596px;
        display: block;
    }

    &__phone {
        height: 450px;
        margin-top: -545px;
        margin-left: -15px;
        display: block;
    }

    &__subheading {
        padding: 30px 16px 0;
        margin-bottom: 8px;
    }

    &__text {
        padding-top: 7px;

        &--centered {
            margin-top: 2px;
            text-align: left;
            width: calc(100% - 32px);
        }
    }

    &__icons {
        width: 200px;
        height: 48px;
        margin: 16px auto 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .tab-content {
        text-align: center;
    }
}

.push-icon {
    width: 48px;
    height: 48px;

    &--windows {
        background: url("../assets/icons/windows.svg") 100% 100%;
        background-size: contain;
    }

    &--apple {
        background: url("../assets/icons/apple.svg") 100% 100%;
        background-size: contain;
    }

    &--android {
        background: url("../assets/icons/android.svg") 100% 100%;
        background-size: contain;
    }
}

@media only screen and (min-width: 768px) {
    .about__tabs {
        width: 100%;
    }
    .about-onclick {
        &__laptop {
            position: absolute;
            right: 0;
            width: 487px;
            height: 405.5px;
            display: block;
            overflow: hidden;
            margin: 60px 0 0 0;
        }
        &__phone {
            margin: 75px 0 60px -400px;
            display: block;
        }
    }
    .about-ipp {
        &__laptop {
            float: right;
            display: block;
            width: 635px;
            height: 294px;
            position: relative;
            margin: 0;
            overflow: visible;;
        }
        &__phone {
            position: relative;
            margin: -70px 240px 0 -160px;
            left: auto;
            display: inline;
        }
    }
    .about-push {
        padding-bottom: 0;

        &__heading {
            margin-top: 73px;
        }

        &__laptop {
            width: 688px;
            height: 528.54px;
            margin: 90px auto 0;
        }

        &__phone {
            margin-top: -630px;
            margin-left: 233px;
        }

        &__subheading {
            padding: 112px 16px 0;
        }

        &__text {
            padding: 21px 52px 0;
            margin-top: -10px;
            margin-bottom: 20px;
            text-align: center;

            &--centered {
                text-align: center;
            }
        }
        .cta {
            max-width: 330px;
            padding-left: 30px;
            padding-right: 30px;
        }
        .tab-content {
            text-align: center;
        }
    }
}

@media only screen and (min-width: 1280px) {
    .about-push {
        display: block;
        max-width: 1280px;
        min-height: 953px;
        margin: 0 auto;
        overflow: visible;
        position: relative;

        .tab-content {
            text-align: left;
        }

        &__heading {
            margin-top: 0;
        }

        &__laptop {
            width: 1117px;
            height: 666px;
            margin-top: 98px;
            margin-left: -550px;
            margin-right: 30px;
            float: left;
        }

        &__phone {
            width: 919.15px;
            height: 1076.33px;
            margin-top: 0;
            bottom: -410px;
            margin-left: 1000px;
            position: absolute;
        }

        &__subheading {
            font-size: 36px;
            line-height: 56px;
            text-align: left;
            padding-top: 211px;
        }

        &__text {
            margin-top: -12px;
            padding-right: 180px;
            text-align: left;

            &--centered {
                text-align: left;
            }
        }

        &__icons {
            margin: 25px 0;
            width: 165px;
            justify-content: space-around;

            & span:hover {
                border-radius: 100%;
                box-shadow: 0 0 10px #1CD6FF;
                transition: 0.3s;
            }
        }
        .cta {
            max-width: 382px;
            padding-left: 50px;
            padding-right: 50px;
        }
    }
    .about-onclick {
        &__laptop {
            position: absolute;
            margin: 0 0 0 705px;
            width: 1117px;
            height: 718px;
            right: auto;
        }
        &__phone {
            float: left;
            position: relative;
            top: 20px;
            margin: 0 0 0 -130px;
        }
        &__text {
            padding-right: 605px;
        }
        &__subheading {
            font-size: 36px;
            line-height: 56px;
            text-align: left;
            padding-top: 140px;
        }
    }
    .about-ipp {
        &__laptop {
            float: left;
            margin: 0 -390px 0 0;
            width: 1145px;
            height: 874px
        }
        &__phone {
            position: absolute;
            left: -146px;
            top: 656px;
            margin: 0;
        }
        &__text {
            padding-right: 60px;
        }
        &__subheading {
            font-size: 36px;
            line-height: 56px;
            text-align: left;
            padding-top: 120px;
        }
    }

    .push-icon {
        width: 36px;
        height: 36px;
    }

}