.team {
    padding-top: 105px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &__heading {
        font-weight: normal;
        font-size: 26px;
        line-height: 56px;
        margin-bottom: 10px;
    }

    &__text {
        margin-top: 0;
        font-size: 18px;
        line-height: 31px;
        text-align: center;
    }

    &__list {
        margin-top: 50px;
        padding-left: 0;
        list-style: none;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
}

.profile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 55px;

    &__name {
        font-weight: normal;
        font-size: 18px;
        line-height: 31px;
        margin-top: 9px;
        margin-bottom: 7px;
    }

    &__position {
        margin-top: 0;
        font-size: 16px;
        line-height: 31px;
    }
}

@media only screen and (min-width: 768px) {
    .team {
        padding-top: 167px;

        &__heading {
            font-size: 36px;
            line-height: 41px;
            margin-bottom: 6px;
        }

        &__text {
            font-size: 26px;
            line-height: 41px;
        }

        &__list {
            margin: 135px auto 16px;
            flex-direction: row;
            justify-content: space-between;
            padding: 0 18px;
            max-width: 700px;
        }
    }

    .profile {
        min-width: 190px;

        &__position {
            margin-top: 13px;
        }
    }
}

@media only screen and (min-width: 1280px) {
    .team {
        padding-top: 190px;

        &__heading {
            font-size: 48px;
            line-height: 56px;
            margin-bottom: 10px;
            max-width: 868px;
        }

        &__text {
            font-size: 36px;
            line-height: 56px;
        }

        &__list {
            margin: 120px auto 0;
            max-width: 880px;
        }
    }
}