.no-focus {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    left: 0;
    top: 0;
    z-index: 6;
    overflow-y: scroll;

    &--hidden {
        display: none;
    }
}

.sales-form {
    padding: 0 22px;

    &--pop-up {
        padding: 0;
    }

    &__heading {
        margin-top: -15px;
        margin-bottom: 48px;
        line-height: 41px;
    }
}

.contact-form {
    width: 100%;
    max-width: 768px;
    padding-bottom: 36px;
    margin-bottom: 45px;
    position: relative;

    &--first {
        margin-top: 45px;
    }

    &__close {
        position: absolute;
        top: 42px;
        right: 17px;
        background: url("../assets/icons/close.svg") no-repeat center;
        width: 24px;
        height: 24px;
        background-size: cover;
        border: none;
        padding: 0;
        outline: none;
        font-size: 0;

        &--white {
            background: url("../assets/icons/close-white.svg") no-repeat center;
            width: 36px;
            height: 36px;
            background-size: cover;
        }
    }

    &--pop-up {
        margin-bottom: 0;
    }

    &__text {
        font-size: 18px;
        line-height: 26px;
        margin-top: 30px;
        margin-bottom: 12px;
    }

    &__radio {
        margin: 23px 0 0;
    }

    &__fields {
        margin-top: 13px;
        padding: 0 17px;

        &--pop-up {
            margin-top: 0;
        }
    }

    &__set {
        margin: 0;
    }

    &__label {
        display: flex;
        flex-direction: column;
        font-size: 12px;
        line-height: 18px;
        color: #8C8C8C;
        transition: 0.2s;
        padding: 0;

        &--hidden {
            opacity: 0;
        }

        &--invalid {
            color: #EC5050;
        }
    }

    &__input {
        margin: 1px 0 11px;
        background: #FFFFFF;
        border: 1px solid #E5E5E5;
        box-sizing: border-box;
        border-radius: 5px;
        padding: 7px 22px;
        width: 100%;
        height: 45px;
        outline: none;
        font-family: Nunito Sans, Nunito, sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        color: #000000;
        transition: 0.3s;

        &--select {
            appearance: none;
        }

        &__textarea {
            height: 164px;
            resize: vertical;
            padding-top: 13px;
            margin-bottom: 36px;
        }

         &::placeholder {
             color: #8C8C8C;
         }

        &:focus {
            border: 1px solid #2297FC;
        }

        &--invalid {
            border: 1px solid #EC5050;
        }
    }

    &__submit {
        width: 103px;
        border: none;
        margin: 35px auto 0;
        display: block;
        padding: 16px 28px;
        font-size: 16px;
        line-height: 22px;
        outline: none;

        &--success {
            width: 240px;
        }

        &--error {
            background: #EC5050;
            width: 115px;

            &:hover {
                background: #EC5050;
            }

            &:active {
                background: #EC5050;
            }
        }
    }
}

[type="radio"]:checked,
[type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
}

[type="radio"]:checked + label,
[type="radio"]:not(:checked) + label {
    position: relative;
    padding-left: 46px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: #666;
    margin-bottom: 0;
}

[type="radio"]:checked + label:before,
[type="radio"]:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 16px;
    height: 16px;
    border: 1px solid #2297FC;
    border-radius: 100%;
    background: #fff;
    margin-left: 17px;
}

[type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
    content: '';
    width: 10px;
    height: 10px;
    background: #2297FC;
    position: absolute;
    top: 4px;
    left: 4px;
    border-radius: 100%;
    margin-left: 17px;
}

[type="radio"]:not(:checked) + label:after {
    opacity: 0;
}

[type="radio"]:checked + label:after {
    opacity: 1;
}

.g-recaptcha {
    display: block;
    margin: -22px auto 0;
    width: 304px;
}

@media only screen and (min-width: 768px) {
    .no-focus {
        display: flex;
        flex-direction: column;
        justify-content: center;

        &--hidden {
            display: none;
        }
    }

    .sales-form {

        &__heading {
            font-size: 26px;
            line-height: 43px;
            margin-top: 62px;
            margin-bottom: 71px;
        }

        &--pop-up {
            overflow: scroll;
        }
    }

    .contact-form {
        max-width: 704px;
        width: 704px;
        padding-bottom: 51px;
        margin-bottom: 33px;

        &--first {
            margin-top: 80px;
        }

        &__close {
            top: 45px;

            &--white {
                top: -50px;
                right: calc(50% - 380px);
            }
        }

        &__text {
            margin-top: 65px;
            margin-left: 6px;
            margin-bottom: 12px;
        }

        &__radio {
            margin-top: 10px;
            margin-left: 5px;
        }

        &__fields {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            margin-bottom: 12px;
            padding: 0 23px;
        }

        &__input {
            width: 300px;
            margin: 0 0 14px 0;

            &__textarea,
            &--full-width {
                width: 660px;
            }
        }
    }

    .g-recaptcha {
        margin: 31px auto 50px;
    }
}

@media only screen and (min-width: 1280px) {
    .sales-form {
        border-bottom: 1px solid #E5E5E5;
        padding-bottom: 50px;

        &__heading {
            font-size: 36px;
            line-height: 56px;
            margin-top: 48px;
        }

        &--pop-up {
            padding: 0;
            border: none;
        }
    }

    .contact-form {
        max-width: 850px;
        width: 850px;
        margin: 0 auto 80px;

        &--first {
            margin-top: 120px;
        }

        &__close {
            right: 90px;

            &--white {
                top: -50px;
                right: calc(50% - 480px);
            }
        }

        &__text {
            margin-left: 79px;
        }

        &__radio {
            margin-top: 5px;
            margin-left: 77px;
        }

        &__fields {
            padding: 0 95px;
        }

        &__input {
            margin: 0 0 14px 0;
        }
    }
}