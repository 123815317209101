.footer {
    &__logo:before {
        content: "";
        display: block;
        background: url("../assets/icons/logo-colored.svg") center no-repeat;
        width: 100px;
        height: 20.63px;
        margin: 60px auto 23px;
    }

    &__form {
        padding: 28px 0 40px;
        background: #FAFAFA;
    }

    &__form-heading {
        font-weight: normal;
        font-size: 20px;
        line-height: 38px;
    }

    &__nav {
        font-family: Nunito Sans, sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 41px;
        letter-spacing: 0.05em;
        color: #000000;
        padding: 17px 16px;
    }

    &__contacts {
        padding: 0 16px;
        font-family: Nunito Sans, sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 41px;
        letter-spacing: 0.05em;
        color: #000000;
    }
}

.form {

    &__label {
        font-size: 0;
    }

    &__input {
        outline: none;
        width: 100%;
        max-width: 328px;
        height: 57px;
        border: 1px solid #E5E5E5;
        box-sizing: border-box;
        border-radius: 5px;
        background-color: transparent;
        display: block;
        margin: 0 auto 17px;
        font-family: Nunito Sans, sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 25px;
        padding-left: 20px;

        &::placeholder {
            font-family: Nunito Sans, sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 25px;
            color: #8C8C8C;
        }
    }

    &__submit {
        width: 100%;
        max-width: 328px;
        height: 57px;
        border-radius: 5px;
        border: none;
        font-family: Nunito Sans, sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 25px;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        color: #FFFFFF;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        background: linear-gradient(251.32deg, #8BFF89 0%, #2BDC72 100%);

        &:hover {
            background: linear-gradient(263.27deg, #6EFF6B 6.27%, #00D254 98.72%);
        }

        &:active {
            background: linear-gradient(263.27deg, #00D254 5.85%, #6EFF6B 98.72%);
        }

        &:disabled {
            background: #DBDBDB;;
        }

        &:before {
            content: "";
            display: block;
            background: url("../assets/icons/send.svg") center no-repeat;
            width: 25px;
            height: 24px;
            margin-right: 12px;
        }

        &:disabled {
            background: #DBDBDB;
        }
    }
}

.two-columns {
    margin-bottom: 36px;
}

.nav {

    &__heading {
        font-size: 16px;
        line-height: 41px;
    }

    &__list {
        margin-top: -10px;
        padding-left: 0;
        list-style: none;
    }

    &__item {
        display: block;
        margin-bottom: 6px;
        text-decoration: none;
        font-weight: normal;
        color: #000000;

        &--hidden {
            display: none;
        }
    }
}

.contacts {

    &__heading {
        margin: 0;
        font-weight: bold;
        font-size: 16px;
        line-height: 41px;
    }

    &__mail {
        color: #2297FC;
        text-decoration: none;
        margin-top: 5px;
        display: block;
    }
}

.meet {

    &__heading {
        margin: 6px 0 18px;
        font-weight: normal;
        font-size: 16px;
        line-height: 41px;
    }

    &__list {
        margin-top: 0;
        padding: 0;
        display: flex;
        gap: 10%;
        flex-wrap: wrap;
        justify-content: center;
        flex-wrap: wrap;
        list-style: none;
        width: 203px;
        row-gap: 5px;
    }

    &__social {
        width: 49px;
        height: 49px;
        display: block;
        font-size: 0;
    }
}

.facebook {
    background: url("../assets/icons/facebook.svg") center no-repeat;
}

.instagram {
    background: url("../assets/icons/instagram.svg") center no-repeat;
}

.linkedin {
    background: url("../assets/icons/linkedin.svg") center no-repeat;
}

.telegram {
    background: url("../assets/icons/Telegram.svg") center no-repeat;
}

.twitter {
    background: url("../assets/icons/twitter.svg") center no-repeat;
}

.youtube {
    background: url("../assets/icons/youtube.svg") center no-repeat;
}

.payment__heading {
    margin: 0;
    font-family: Nunito Sans, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 41px;
}

.card-brands {
    margin: 0;
}

.copyrights {
    padding: 7px 0;
    background: linear-gradient(239.05deg, #37D9FC 0%, #0538FB 100%);

    &__list {
        padding-left: 0;
        display: flex;
        justify-content: space-around;
        align-items: center;
        list-style: none;
    }

    &__link {
        text-decoration: none;
        font-family: Nunito Sans, sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 26px;
        color: #FFFFFF;
    }

    &__text {
        font-family: Nunito Sans, sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 26px;
        text-align: center;
        color: #FFFFFF;
        opacity: 0.6;
    }
}

@media only screen and (min-width: 768px) {
    .footer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        &__logo {
            width: 571px;
            margin: 0 auto;
            text-align: center;

            &:before {
                width: 160px;
                height: 33px;
                margin-bottom: 42px;
            }
        }

        &__form {
            margin-top: 58px;
            padding: 40px 0 40px;
            background: #FAFAFA;
            width: 100%;
        }

        &__nav {
            float: left;
            margin: 29px 158px 0 23px;
        }

        &__contacts {
            width: 307px;
            margin-top: 61px;
            padding-left: 0;
        }
    }

    .one-column {
        width: 100%;
    }

    .two-columns {
        display: flex;
    }

    .form {
        width: 567px;
        margin: 36px auto 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &__input {
            display: inline-block;
            background: #FFFFFF;
            width: 336px;
            margin: 0;
            padding-left: 32px;

        }

        &__submit {
            width: 210px;
        }
    }

    .two-columns {
        width: 768px;
        margin-bottom: 36px;
    }

    .meet {
        margin-top: 50px;

        &__heading {
            margin-bottom: 15px;
        }
    }

    .payment__heading {
        margin-top: 50px;
    }

    .copyrights {
        height: 93px;
        width: 100%;

        &__list {
            padding: 0 31px;
            min-width: 380px;
            justify-content: space-between;
        }
    }
}

@media only screen and (min-width: 1280px) {
    .footer {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: stretch;

        &__logo {
            text-align: left;
            padding-left: 4px;
            width: 337px;

            &::before {
                margin: 61px 0 25px;
            }
        }

        &__form {
            background: none;
            margin: 0;
            padding: 5px 0 0 4px;
        }

        &__form-heading {
            text-align: left;
            font-size: 18px;
            line-height: 34px;
            margin-bottom: 19px;
        }

        &__nav {
            float: left;
            margin: 29px 85px 0 0;
            padding-left: 5px;
        }
    }

    .one-column {
        width: 350px;
        height: 100%;
        margin-right: 130px;
    }

    .two-columns {
        width: 640px;
        margin-bottom: 50px;
    }

    .form {
        margin: 0;
        width: 100%;

        &__input {
            width: 258px;
            height: 45px;
            padding-left: 22px;
            font-size: 16px;
            line-height: 31px;

            &::placeholder {
                font-size: 16px;
                line-height: 31px;
            }
        }

        &__submit {
            font-size: 0;
            width: 80px;
            height: 45px;

            &:before {
                margin-right: 0;
            }
        }
    }

    .meet {
        margin-top: 33px;

        &__list {
            width: 152px;
        }

        &__social {
            width: 36px;
            height: 36px;
            background-size: contain;
        }
    }

    .copyrights {
        padding: 0 calc((100% - 1110px) / 2);
        height: 87px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &__link {
            font-size: 16px;
            line-height: 31px;
            margin-right: 40px;
        }

        &__text {
            font-size: 16px;
            line-height: 31px;
        }

        &__list {
            padding: 0;
        }
    }
}