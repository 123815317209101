.main-accent {
    background: url("../assets/backgrounds/circles.svg") no-repeat 0 10px, url("../assets/backgrounds/a_people.svg") no-repeat center 84.5%, url("../assets/backgrounds/accent-background.svg");
    background-size: auto, auto, contain;
    padding: 100px 17px 0;
    text-align: center;
    min-height: 1003px;

    &--promo-ru {
        background: url("../assets/backgrounds/circles.svg") no-repeat 0 10px, url("../assets/backgrounds/a_people_ru.svg") no-repeat center 84.5%, url("../assets/backgrounds/accent-background.svg");
    }

    &--publishers {
        background: url("../assets/backgrounds/circles.svg") no-repeat 0 10px, url("../assets/backgrounds/pub_people.svg") no-repeat center 74%, url("../assets/backgrounds/accent-background.svg");
    }

    &--blank {
        background: url("../assets/backgrounds/circles.svg") no-repeat 0 10px, url("../assets/backgrounds/accent-background.svg");
        background-size: auto, contain;
    }

    &__text {
        font-family: Nunito Sans, Nunito, sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 30px;
        text-align: center;
        color: #FFFFFF;
        margin-top: 32px;
    }

    &__cta {
        margin: 14px 0 0;
        text-transform: uppercase;
        width: 100%;
        max-width: 300px;
    }

    &__clickme {
        display: none;
    }
}

@media only screen and (min-width: 768px) {
    .main-accent {
        padding: 159px 100px;
        background: url("../assets/backgrounds/a_people_tablet.svg") no-repeat center 97%, url("../assets/backgrounds/circles.svg") no-repeat -80px -15%, url("../assets/backgrounds/accent-background-tablet.svg") no-repeat top center;
        background-size: auto, 650px 860px, 100%;
        min-height: 882px;

        &--promo-ru {
            background: url("../assets/backgrounds/a_people_tablet_ru.svg") no-repeat center 97%, url("../assets/backgrounds/circles.svg") no-repeat -80px -15%, url("../assets/backgrounds/accent-background-tablet.svg") no-repeat top center;
        }

        &--publishers {
            background: url("../assets/backgrounds/pub_people_tablet.svg") no-repeat calc(50% - 37px) 90%, url("../assets/backgrounds/circles.svg") no-repeat -80px -20%, url("../assets/backgrounds/accent-background-tablet.svg") no-repeat top center;
            background-size: auto, 650px 860px, 100%;
        }

        &--blank {
            background: url("../assets/backgrounds/circles.svg") no-repeat -80px -15%, url("../assets/backgrounds/accent-background-tablet.svg") no-repeat top center;
            background-size: 650px 860px, 100%;
        }

        &__text {
            margin-top: 42px;
            font-weight: 600;
            font-size: 24px;
            line-height: 33px;
        }

        &__cta {
            margin-bottom: 507px;
        }
    }
}

@media only screen and (min-width: 1280px) {
    .main-accent {
        padding: 226px calc((100% - 1110px) / 2) 0;
        background: url("../assets/backgrounds/circles.svg") no-repeat -150px -125%, url("../assets/backgrounds/a_people_desktop.svg") no-repeat calc(50% + 30px) top, url("../assets/backgrounds/accent-background-desktop.svg") no-repeat top center;
        background-size: 938.64px 938.64px, auto, 100%;
        min-height: 912px;
        text-align: left;

        &--promo-ru {
            background: url("../assets/backgrounds/circles.svg") no-repeat -150px -125%, url("../assets/backgrounds/a_people_desktop_ru.svg") no-repeat calc(50% + 30px) top, url("../assets/backgrounds/accent-background-desktop.svg") no-repeat top center;
        }

        &--publishers {
            background: url("../assets/backgrounds/circles.svg") no-repeat -150px -125%, url("../assets/backgrounds/pub_people_desktop.svg") no-repeat calc(50% + 257px) 41%, url("../assets/backgrounds/accent-background-desktop.svg") no-repeat top center;
            background-size: 938.64px 938.64px, auto, 100%;
        }

        &--blank {
            background: url("../assets/backgrounds/circles.svg") no-repeat -150px -125%, url("../assets/backgrounds/accent-background-desktop.svg") no-repeat top center;
            background-size: 938.64px 938.64px, 100%;

            .main-accent__text,
            .heading--accent {
                display: none;
            }
        }

        &__text {
            font-weight: 600;
            font-size: 24px;
            line-height: 33px;
            text-align: left;
            width: 543px;
        }

        &__cta {
            margin: 32px 0 0;
            padding: 22px 0;
            //min-width: 332px;
        }

        &__clickme {
            display: block;
            position: absolute;
            width: 330px;
            height: 91px;
            font-size: 0;
            top: 335px;
            left: calc(50% + 95px);
        }
    }
}