.cta {
    max-width: 333px;
    padding: 18px 0;
    background: linear-gradient(250.71deg, #8BFF89 0%, #2BDC72 100%);
    border-radius: 5px;
    font-family: Nunito Sans, Nunito, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 27px;
    letter-spacing: 0.05em;
    color: #FFFFFF;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    text-transform: uppercase;
    white-space: nowrap;

    &:hover {
        background: linear-gradient(263.27deg, #6EFF6B 6.27%, #00D254 98.72%);
    }

    &:active {
        background: linear-gradient(263.27deg, #00D254 5.85%, #6EFF6B 98.72%);
    }

    &:disabled {
        background: #DBDBDB;;
    }
}

.secondary {
    box-sizing: border-box;
    width: 100%;
    max-width: 204px;
    padding: 17px 54px;
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 5px;
    font-family: Nunito Sans, Nunito, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 27px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    color: #FFFFFF;
    background-color: transparent;
    outline: none;
    text-wrap: none;

    &:hover {
        background: #FFFFFF;
        color: #2297FC;
    }

    &:active {
        background: #FFFFFF;
        color: #1A71BD;
    }

    &--accent {
        max-width: 181px;
    }
}

.cta-meeting {
    width: 247px;
    height: 57px;
    background: linear-gradient(251.32deg, #8BFF89 0%, #2BDC72 100%);
    border-radius: 5px;
    font-family: Nunito Sans, Nunito, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 25px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #FFFFFF;
    border: none;
    outline: none;
    text-align: center;
    display: block;
    margin: 0 auto;

    &:hover {
        background: linear-gradient(263.27deg, #6EFF6B 6.27%, #00D254 98.72%);
    }

    &:active {
        background: linear-gradient(263.27deg, #00D254 5.85%, #6EFF6B 98.72%);
    }

    &:disabled {
        background: #DBDBDB;;
    }
}

@media only screen and (min-width: 768px) {
    .cta {
        font-size: 18px;
        line-height: 25px;
        padding: 18px 0;
        max-width: 254px;
    }

    .cta-meeting {
        width: 231px;
        height: 61px;
    }

    .secondary {
        font-size: 18px;
        line-height: 25px;
        padding: 18px 32px;
        max-width: 185px;
        box-sizing: content-box;
    }
}

@media only screen and (min-width: 1280px) {
    .cta {
        font-size: 22px;
        line-height: 30px;
        padding: 22px 50px;
        max-width: 382px;
    }

    .secondary {
        padding: 21px 50px;
        font-size: 22px;
        line-height: 30px;
        max-width: 322px;
        transition: 0.2s;
        box-sizing: content-box;
    }

    .cta-meeting {
        width: 204px;
        height: 54px;
        font-size: 16px;
        line-height: 22px;
        margin-right: 51px;
    }
}