.signup {
    background: linear-gradient(250.85deg, #37D9FC 5.42%, #0538FB 100%);
    padding-top: 59px;
    padding-bottom: 78px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &__heading {
        font-size: 26px;
        line-height: 38px;
        color: #FFFFFF;
        padding: 0 24px;
    }

    &__text {
        padding: 11px 24px;
        font-size: 16px;
        line-height: 31px;
        text-align: center;
        color: #FFFFFF;
    }

    &__cta {
        margin: 4px auto 16px;
        min-width: 315px;
    }
}

@media only screen and (min-width: 768px) {
    .signup {
        padding: 96px 48px 105px;
        background: url("../assets/backgrounds/signup_background_tablet.svg") no-repeat center, linear-gradient(250.85deg, #37D9FC 5.42%, #0538FB 100%);
        background-size: 100%;
        display: block;

        &__heading {
            font-size: 30px;
            line-height: 48px;
            width: 472px;
            margin: 24px auto;
        }

        &__text {
            font-size: 20px;
            line-height: 33px;
            width: 544px;
            margin: 26px auto 47px;
        }

        &__cta {
            min-width: 275px;
        }

        &__secondary {
            width: 185px;
            margin-left: 30px;
        }
    }
}

@media only screen and (min-width: 1280px) {
    .signup {
        padding: 140px 0;
        height: 271px;
        background: url("../assets/backgrounds/signup_background_desktop.svg") no-repeat center, linear-gradient(250.85deg, #37D9FC 5.42%, #0538FB 100%);

        &__heading {
            font-size: 36px;
            line-height: 56px;
            width: 1000px;
            margin: 0 auto;
        }

        &__text {
            font-size: 18px;
            line-height: 34px;
            width: 589px;
            margin: 7px auto 40px;
        }

        &__cta {
            margin-right: 22px;
            min-width: 260px;
            max-width: 260px;
        }

        &__secondary {
            width: 224px;
        }
    }
}