/* nunito-sans-300 - latin */
@font-face {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 300;
    src: url('../assets/fonts/nunito-sans-v5-latin-300.eot'); /* IE9 Compat Modes */
    src: local('Nunito Sans Light'), local('NunitoSans-Light'),
    url('../assets/fonts/nunito-sans-v5-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../assets/fonts/nunito-sans-v5-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
    url('../assets/fonts/nunito-sans-v5-latin-300.woff') format('woff'), /* Modern Browsers */
    url('../assets/fonts/nunito-sans-v5-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../assets/fonts/nunito-sans-v5-latin-300.svg#NunitoSans') format('svg'); /* Legacy iOS */
}
/* nunito-sans-regular - latin */
@font-face {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 400;
    src: url('../assets/fonts/nunito-sans-v5-latin-regular.eot'); /* IE9 Compat Modes */
    src: local('Nunito Sans Regular'), local('NunitoSans-Regular'),
    url('../assets/fonts/nunito-sans-v5-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../assets/fonts/nunito-sans-v5-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('../assets/fonts/nunito-sans-v5-latin-regular.woff') format('woff'), /* Modern Browsers */
    url('../assets/fonts/nunito-sans-v5-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../assets/fonts/nunito-sans-v5-latin-regular.svg#NunitoSans') format('svg'); /* Legacy iOS */
}
/* nunito-sans-600 - latin */
@font-face {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 600;
    src: url('../assets/fonts/nunito-sans-v5-latin-600.eot'); /* IE9 Compat Modes */
    src: local('Nunito Sans SemiBold'), local('NunitoSans-SemiBold'),
    url('../assets/fonts/nunito-sans-v5-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../assets/fonts/nunito-sans-v5-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
    url('../assets/fonts/nunito-sans-v5-latin-600.woff') format('woff'), /* Modern Browsers */
    url('../assets/fonts/nunito-sans-v5-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../assets/fonts/nunito-sans-v5-latin-600.svg#NunitoSans') format('svg'); /* Legacy iOS */
}
/* nunito-sans-700 - latin */
@font-face {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 700;
    src: url('../assets/fonts/nunito-sans-v5-latin-700.eot'); /* IE9 Compat Modes */
    src: local('Nunito Sans Bold'), local('NunitoSans-Bold'),
    url('../assets/fonts/nunito-sans-v5-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../assets/fonts/nunito-sans-v5-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
    url('../assets/fonts/nunito-sans-v5-latin-700.woff') format('woff'), /* Modern Browsers */
    url('../assets/fonts/nunito-sans-v5-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../assets/fonts/nunito-sans-v5-latin-700.svg#NunitoSans') format('svg'); /* Legacy iOS */
}

/* nunito-300 - cyrillic */
@font-face {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 300;
    src: url('../assets/fonts/nunito-v12-cyrillic-300.eot'); /* IE9 Compat Modes */
    src: local('Nunito Light'), local('Nunito-Light'),
    url('../assets/fonts/nunito-v12-cyrillic-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../assets/fonts/nunito-v12-cyrillic-300.woff2') format('woff2'), /* Super Modern Browsers */
    url('../assets/fonts/nunito-v12-cyrillic-300.woff') format('woff'), /* Modern Browsers */
    url('../assets/fonts/nunito-v12-cyrillic-300.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../assets/fonts/nunito-v12-cyrillic-300.svg#Nunito') format('svg'); /* Legacy iOS */
}
/* nunito-regular - cyrillic */
@font-face {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 400;
    src: url('../assets/fonts/nunito-v12-cyrillic-regular.eot'); /* IE9 Compat Modes */
    src: local('Nunito Regular'), local('Nunito-Regular'),
    url('../assets/fonts/nunito-v12-cyrillic-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../assets/fonts/nunito-v12-cyrillic-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('../assets/fonts/nunito-v12-cyrillic-regular.woff') format('woff'), /* Modern Browsers */
    url('../assets/fonts/nunito-v12-cyrillic-regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../assets/fonts/nunito-v12-cyrillic-regular.svg#Nunito') format('svg'); /* Legacy iOS */
}
/* nunito-600 - cyrillic */
@font-face {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 600;
    src: url('../assets/fonts/nunito-v12-cyrillic-600.eot'); /* IE9 Compat Modes */
    src: local('Nunito SemiBold'), local('Nunito-SemiBold'),
    url('../assets/fonts/nunito-v12-cyrillic-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../assets/fonts/nunito-v12-cyrillic-600.woff2') format('woff2'), /* Super Modern Browsers */
    url('../assets/fonts/nunito-v12-cyrillic-600.woff') format('woff'), /* Modern Browsers */
    url('../assets/fonts/nunito-v12-cyrillic-600.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../assets/fonts/nunito-v12-cyrillic-600.svg#Nunito') format('svg'); /* Legacy iOS */
}
/* nunito-700 - cyrillic */
@font-face {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 700;
    src: url('../assets/fonts/nunito-v12-cyrillic-700.eot'); /* IE9 Compat Modes */
    src: local('Nunito Bold'), local('Nunito-Bold'),
    url('../assets/fonts/nunito-v12-cyrillic-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../assets/fonts/nunito-v12-cyrillic-700.woff2') format('woff2'), /* Super Modern Browsers */
    url('../assets/fonts/nunito-v12-cyrillic-700.woff') format('woff'), /* Modern Browsers */
    url('../assets/fonts/nunito-v12-cyrillic-700.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../assets/fonts/nunito-v12-cyrillic-700.svg#Nunito') format('svg'); /* Legacy iOS */
}