#svgMap {
    display: none;
}

.map {
    padding: 0 32px 100px;
    margin-top: -835px;
}

.map-data__search input,
.map-data__search select {
    width: 100%;
    height: 50px;
    display: block;
    margin: 0 auto;
    font-family: 'Nunito Sans', -apple-system, BlinkMacSystemFont, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    border: 1px solid #E5E5E5;
    box-sizing: border-box;
    border-radius: 5px;
    padding-left: 10px;
    background-color: #FFFFFF;
    outline: none;
    max-width: 917px;
}

.map-data__search select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url("data:image/svg+xml;utf8,<svg fill='darkgray' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
    background-repeat: no-repeat;
    background-position-x: 97%;
    background-position-y: 50%;
    background-size: 30px 30px;
}

.sortby {
    font-family: 'Nunito Sans', -apple-system, BlinkMacSystemFont, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #FFFFFF;
    display: block;
    margin: 23px 0 6px 4px;
}
.prices-accent .main-accent__text {
    margin-top: 134px;
}
.center-block {
    display: flex;
    justify-content: center;
    position: relative;
    top: -120px;
}
.tabs-rates {
    display: flex;
    justify-content: center;
    border: 1px #fff solid;
    padding: 4px;
    border-radius: 5px;
    margin: 58px 0 0 0;

    &__tab {
        font-family: 'Nunito Sans', -apple-system, BlinkMacSystemFont, sans-serif;
        font-weight: bold;
        font-size: 13px;
        line-height: 25px;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        color: #fff;
        height: 50px;
        display: flex;
        background: none;
        min-width: 104px;
        padding: 0 10px;
        align-items: center;
        justify-content: center;
        margin: 0 2px 0 0;
        border-radius: 5px;
        border: none;
        white-space: nowrap;

        &:last-child {
            margin: 0;
        }
        &:hover {
            background: rgba(255, 255, 255, .2);
        }
        &--active, &--active:hover {
            background: #fff;
            color: #2297FC;
        }
    }

}
@media only screen and (max-width: 767px) {
    .map-data,
    .map-data tr,
    .map-data tr td {
        display: block;
    }

    .map-data {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 46px auto 0;
    }

    .map-data thead {
        display: none;
    }

    .map-data tr {
        padding: 10px 10px 4px;
        background: #F8F8F8;
        border-radius: 5px;
        margin-bottom: 6px;
    }

    .map-data td {
        width: 100%;
        font-family: 'Nunito Sans', -apple-system, BlinkMacSystemFont, sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 19px;
        text-align: right;
        letter-spacing: 0.01em;
        color: #000000;
        margin: 5px 0;
        position: relative;
    }

    .map-data td:first-child {
        min-width: 296px;
        text-align: left;
        font-weight: bold;
        font-size: 14px;
        line-height: 19px;
        margin-bottom: 9px;
    }

    .map-data td:nth-child(2) {
        display: none;
    }

    .map-data td:nth-child(3):before {
        content: "Impressions";
        position: absolute;
        left: 0;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 19px;
        letter-spacing: 0.01em;
        color: rgba(0, 0, 0, 0.6);
    }

    .map-data td:nth-child(4):before {
        content: "Clicks";
        position: absolute;
        left: 0;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 19px;
        letter-spacing: 0.01em;
        color: rgba(0, 0, 0, 0.6);
    }

    .map-data td:nth-child(5):before {
        content: "CPC, $";
        position: absolute;
        left: 0;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 19px;
        letter-spacing: 0.01em;
        color: rgba(0, 0, 0, 0.6);
    }

    .map-data .nothing-found td:nth-child(3),
    .map-data .nothing-found td:nth-child(4),
    .map-data .nothing-found td:nth-child(5) {
        content: "";
        display: none;
    }
}

@media only screen and (min-width: 768px) {
    .tabs-rates {
        &__tab {
            font-size: 18px;
            padding: 0 20px;
            min-width: 145px;
        }
    }
    .center-block {
        top: -130px;
    }
    .map-data__search select,
    .sortby {
        display: none;
    }
    .prices-accent .main-accent__text {
        margin-top: 134px;
    }

    .map {
        padding: 0 20px;
        margin-top: -855px;
    }

    .map-data tr,
    .map-data tr td {
        display: table-row;
    }

    .map-data tr td {
        display: table-cell;
        text-align: left;
    }

    .map-data {
        width: 100%;
        max-width: 917px;
        margin: 25px auto 200px;
        background: #FFFFFF;
        box-shadow: 2px 2px 24px rgba(11, 0, 77, 0.08);
        border-radius: 7px;
        border-collapse: collapse;
        display: table;
        font-family: 'Nunito Sans', -apple-system, BlinkMacSystemFont, sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 19px;
        letter-spacing: 0.01em;
        color: #000000;
    }

    .map-data thead th {
        height: 50px;
        padding-left: 32px;
        text-align: left;
        font-weight: bold;
        font-size: 16px;
        line-height: 22px;
        letter-spacing: 0.01em;
        color: #000000;
    }

    .map-data thead th span {
        border-bottom: 1px dashed black;
        cursor: pointer;
    }

    .map-data tbody td {
        height: 64px;
        padding-left: 32px;
        max-width: 120px;
    }

    .map-data tbody tr:nth-child(2n+1) {
        background: #F8F8F8;
    }

    .map-data tbody td:nth-child(-n+2) {
        font-weight: bold;
    }
}

@media only screen and (min-width: 1280px) {
    .center-block {
        top: 0;
    }
    #svgMap {
        display: block;
    }

    .map {
        display: block;
        width: 1110px;
        margin: -1060px auto 0;
    }

    .map .svgMap-map-wrapper {
        background: rgba(0, 0, 0, 0.08);
        height: 480px;
        border-radius: 5px;
    }

    .map-data {
        margin-top: 45px
    }
}