.referral {
    padding: 60px 0 33px;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__heading {
        padding: 0 16px;
        margin-bottom: 16px;
        font-size: 26px;
        line-height: 38px;
        text-align: center;
    }

    &__text {
        padding: 0 16px;
        margin-bottom: 32px;
    }

    &__img {
        margin-top: -180px;
        margin-left: -100px;
    }
}

@media only screen and (min-width: 768px) {
    .referral {
        padding-top: 95px;

        &__heading {
            font-size: 30px;
            line-height: 48px;
            max-width: 384px;
            margin-bottom: 10px;
        }

        &__text {
            margin-top: 10px;
            text-align: center;
            padding: 0 60px;
        }

        &__img {
            margin-top: -190px;
            margin-left: 0;
        }
    }
}

@media only screen and (min-width: 1280px) {
    .referral {
        display: block;
        padding-left: 70px;
        padding-top: 131px;
        padding-bottom: 115px;
        position: relative;
        max-width: 1210px;
        margin: 0 auto;

        &__heading {
            text-align: left;
            font-size: 36px;
            line-height: 56px;
            max-width: 445px;
        }

        &__text {
            margin-top: 18px;
            text-align: left;
            width: 597px;
            padding: 0 15px;
        }

        &__img {
            display: block;
            position: absolute;
            margin: 0 auto;
            top: 47%;
            left: 92%;
            transform: translate(-92%, -47%);
        }
    }
}