.statistics {
    margin-top: 30px;
    background: url("../assets/backgrounds/benefits-background.svg") no-repeat;
    background-size: 100% auto;

    &__header {
        display: none;
    }

    &__list {
        padding-top: 70px;
        background: url("../assets/backgrounds/circle.svg") 121% 43% no-repeat;
        list-style-image: url("../assets/icons/list-dot.svg");
        padding-left: 60px;
        margin: 0 auto;
        width: 280px;
    }

    &__item {
        margin: 70px 0;

        &--pushed {
            margin-left: 38px;
        }
    }

    &__cards {
        padding-top: 29px;
    }
}

@media only screen and (min-width: 768px) {
    .statistics {
        margin-top: -10px;
        padding-top: 0;
        background: url("../assets/backgrounds/benefits-background-tablet.svg") no-repeat, url("../assets/backgrounds/stats_background_numbers.svg") 123% 9% no-repeat, url("../assets/backgrounds/circle-light-tablet.svg") calc(50% + 450px) 620px no-repeat, url("../assets/backgrounds/circle-dark-tablet.svg") calc(50% - 400px) 1930px no-repeat;
        background-size: contain, 670px 823px, 1317px 1317px, 571px 571px;

        &__list {
            padding-top: 160px;
            width: 582px;
            background: url("../assets/backgrounds/circle-tablet.svg") 134.9% 41% no-repeat;
        }
        &__item {
            margin: 75px 0 79px;

            &--pushed {
                margin-left: 38px;
            }
        }

        &__cards {
            padding-top: 89px;
        }
    }
}

@media only screen and (min-width: 1280px) {
    .statistics {
        background: none;
        padding-top: 108px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        &__list {
            background: url("../assets/backgrounds/chart-desktop.svg") center 32px no-repeat;
            display: flex;
            width: 100%;
            list-style: none;
            padding: 0;
            justify-content: left;
            max-width: 1280px;
        }

        &__item {
            margin-top: 61px;
            margin-left: 20px;

            &:nth-child(1) {
                margin-top: 229px;
                margin-left: 70px;
            }

            &:nth-child(2) {
                margin-top: 179px;
                margin-left: 50px;
            }

            &:nth-child(3) {
                margin-top: 104px;
                margin-left: 10px;
            }
        }

        &__cards {
            margin: 0 auto;
            width: 1110px;
            display: flex;
            justify-content: space-between;
            padding-top: 80px;
            opacity: 0;
            transform: translateY(+400px);
            transition: 600ms ease-out;
            transition-property: transorm opacity;

            &--visible {
                opacity: 1;
                transform: translateY(0px);
            }
        }
    }
}