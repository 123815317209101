.header {
    height: 64px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    width: 100%;

    &--static {
        background: linear-gradient(225.82deg, #37D9FC 0%, #0538FB 100%);
    }

    &--fixed {
        position: fixed;
        background: linear-gradient(225.82deg, #37D9FC 0%, #0538FB 100%);
        box-shadow: 2px 2px 24px rgba(11, 0, 77, 0.08);
        z-index: 4;
    }

    &--hamburger {
        position: fixed;
        flex-wrap: wrap;
        background: #FFFFFF;
        width: 100%;
    }

    &__logo {
        margin-left: 16px;
        width: 139px;
        height: 28.67px;
        background-size: cover;
        background: url("../assets/icons/logo-white.svg") no-repeat center;
        font-size: 0;

        &--hamburger {
            margin: 17px 16px;
            background: url("../assets/icons/logo-colored.svg") no-repeat center;
        }
    }

    &__menu {
        margin-right: 16px;
        background: url("../assets/icons/hamburger-menu.svg") no-repeat center;
        width: 24px;
        height: 24px;
        background-size: cover;
        border: none;
        padding: 0;
        outline: none;
        font-size: 0;

        &--hamburger {
            margin: 20px 16px;
            background: url("../assets/icons/close.svg") no-repeat center;
        }
    }

    &__nav {
        display: none;

        &--hamburger {
            border-top: 1px solid #E5E5E5;
            margin: 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            height: 100vh;
            width: 100vw;
            background-color: #FFFFFF;
        }
    }
}

.nav {
    list-style: none;
    padding: 34px 0 0;
    overflow-y: scroll;

    &__link {
        font-size: 22px;
        line-height: 56px;
        text-decoration: none;
        color: #000000;
    }

    &__secondary {
        margin: 41px 0 15px;
        min-width: 326px;
        height: 57px;
        border: 1px solid #2BDC72;
        color: #000000;
        font-weight: bold;
        font-size: 18px;
        line-height: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;

        &::before {
            content: "";
            background: url("../assets/icons/user.svg") no-repeat center;
            width: 24px;
            height: 24px;
            display: inline-block;
            margin-right: 15px;
        }
    }

    &__cta {
        min-width: 328px;
        min-height: 23px;
    }
}

@media only screen and (min-width: 768px) {
    .header {
        height: 82px;
        width: 100%;

        &__logo {
            width: 160px;
            height: 33px;
            background-size: cover;
            margin-left: 40px;
        }

        &__menu {
            width: 42px;
            height: 42px;
            margin-right: 35px;

            &--hamburger {
                width: 42px;
                height: 42px;
                background-size: contain;
            }
        }
    }

    .nav {
        padding-top: 97px;

        &__link {
            font-size: 32px;
            line-height: 98px;
        }

        &__secondary {
            min-width: 341px;
            height: 59px;
            margin-top: 80px;
            margin-bottom: 35px;
        }

        &__cta {
            padding: 0;
            min-width: 343px;
            height: 61px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}

@media only screen and (min-width: 1280px) {
    .header {
        height: 82px;
        width: calc(100% - 170px);
        max-width: 1110px;
        padding: 0 calc((100% - 1110px) / 2);

        &__logo {
            margin: 0;
            width: 196px;
            height: 41px;
            background-size: cover;
            transform-origin: left center;
            transition: 0.3s ease-in-out;

            &--fixed {
                transform: scale(0.8)
            }
        }

        &__menu {
            display: none;
        }

        &__nav {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }

    .nav {
        overflow: hidden;
        padding: 0;

        &__link {
            font-weight: bold;
            font-size: 16px;
            line-height: 22px;
            color: #FFFFFF;
            margin-right: 12px;
        }

        &__secondary {
            margin: 0 23px 0 21px;
            padding: 0;
            width: auto;
            height: 100%;
            min-width: 103px;
            min-height: 45px;
            border: none;
            font-weight: bold;
            font-size: 16px;
            line-height: 22px;
            color: #FFFFFF;
            text-transform: capitalize;
            transition: 0.2s;

            &::before {
                background: url("../assets/icons/user_desktop.svg") no-repeat center;
                width: 16px;
                height: 16px;
                display: inline-block;
                margin-right: 5px;
            }

            &:hover {
                background: rgba(255, 255, 255, 0.3);
                color: #FFFFFF;
            }

            &:active {
                background: #FFFFFF;
                color: #2297FC;

                &:before {
                    background: url("../assets/icons/user_desktop_active.svg") no-repeat center;
                }
            }
        }

        &__cta {
            background: rgba(255, 255, 255, 0.3);
            padding: 0 16px;
            width: calc(100% - 32px);
            height: 100%;
            min-width: 69px;
            min-height: 45px;
            font-weight: bold;
            font-size: 16px;
            line-height: 31px;
            text-transform: capitalize;
            display: flex;
            justify-content: center;
            align-items: center;
            transition: 0.2s;

            &:hover {
                background: #FFFFFF;
                color: #2297FC;
            }

            &:active {
                background: #FFFFFF;
                color: #1A71BD;
            }
        }
    }
}