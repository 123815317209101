.key-features {
    padding: 51px 0 35px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__heading {
        padding: 0 32px 3px;
    }

    &__description {
        margin: 0;
        font-size: 24px;
        line-height: 38px;
        text-align: center;
    }

    &__tabs {
        margin: 46px 0 35px;
        width: 100%;
        max-width: 329px;
        height: 60px;
    }

    &__img img {
        width: 328px;
        height: 192px;
        box-shadow: 6px 6px 32px rgba(21, 58, 89, 0.12);
        display: block;
        margin: 0 auto;
    }

    &__list {
        list-style-image: url("../assets/icons/list-dot.svg");
        padding-left: 63px;
        padding-top: 41px;

        &--text {
            padding-top: 15px;
        }
    }

    &__paragraph {
        padding-top: 41px;
    }

    &__item {
        margin-bottom: 16px;
    }

    &__text {
        position: relative;
        top: -15px;
        left: 6px;
    }
}

@media only screen and (min-width: 768px) {
    .key-features {
        margin-top: 85px;

        &__heading {
            font-size: 30px;
            line-height: 48px;
            margin-bottom: 35px;
        }

        &__description {
            font-size: 20px;
            line-height: 33px;
        }

        &__tabs {
            margin: 57px 0 35px;
            width: 100%;
            max-width: 700px;
            height: 152px;
        }

        &__img img {
            margin-top: 35px;
            width: 688px;
            height: 402px;
        }

        &__list {
            margin: 25px auto 0;
            width: 400px;
            padding-left: 56px;
        }

        &__item {
            margin-bottom: 24px;
        }

        &__paragraph {
            width: 454px;
            margin: 0 auto;
        }
    }
}

@media only screen and (min-width: 1280px) {
    .key-features {
        margin-top: 95px;

        &__heading {
            font-size: 48px;
            line-height: 57px;
        }

        &__description {
            margin-top: 5px;
            max-width: 620px;
            font-size: 36px;
            line-height: 59px;
        }

        &__tabs {
            max-width: 1113px;
            height: auto;
            margin-bottom: 17px;
        }

        &__img img {
            width: 634px;
            height: 370px;
            float: left;
        }

        &__list {
            width: 380px;
            margin-left: 17px;
            margin-top: 0;
        }

        &__item {
            margin-bottom: 46px;

            &--densed {
                margin-bottom: 0;
            }
        }

        &__paragraph {
            width: 380px;
        }
    }
}