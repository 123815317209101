.cookie {
    position: fixed;
    bottom: 0;
    padding: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #FFFFFF;
    box-shadow: 2px -2px 24px rgba(11, 0, 77, 0.08);
    z-index: 5;

    &--accepted {
        display: none;
    }

    &__text {
        text-align: center;
        padding: 0;
        margin: 0;
        font-size: 14px;
        line-height: 26px;
    }

    &__cta {
        margin-top: 32px;
        border: none;
        outline: none;
        padding: 16px 40px;
        width: auto;
    }
}

@media only screen and (min-width: 768px) {
    .cookie {
        padding: 24px;
        flex-direction: row;

        &__text {
            text-align: left;
        }

        &__cta {
            margin-top: 0;
            margin-left: 6px;
            min-width: 177px;;
        }
    }
}

@media only screen and (min-width: 1280px) {
    .cookie {
        padding: 24px calc((100% - 1110px) / 2);

        &__cta {
            margin-left: 24px;
            min-width: 187px;;
        }
    }
}