.heading {
    font-family: Nunito Sans, Nunito, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 32px;
    line-height: 48px;
    text-align: center;
    color: #000000;

    &--accent {
        font-weight: bold;
        font-size: 30px;
        color: #FFFFFF;
    }

    &--subsection {
        font-size: 26px;
        line-height: 38px;
    }

    &--benefits {
        font-size: 20px;
        line-height: 26px;
    }

    &--card {
        font-weight: bold;
        font-size: 18px;
        line-height: 24px;
        text-align: center;
        letter-spacing: 0.1em;
    }

    &--rates {
        display: none;
    }

    &--pop-up {
        font-size: 24px;
    }

    &--hidden {
        visibility: hidden;
    }
}

@media only screen and (min-width: 768px) {
    .heading {
        font-size: 44px;
        line-height: 52px;

        &--pop-up {
            font-size: 24px;
        }

        &--subsection {
            font-size: 30px;
            line-height: 56px;
        }

        &--benefits {
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 26px;
        }

        &--card {
            font-weight: bold;
            font-size: 18px;
            line-height: 24px;
        }

        &--rates {
            margin-top: 64px;
            color: #FFFFFF;
        }
    }
}

@media only screen and (min-width: 1280px) {
    .heading {
        font-size: 48px;
        line-height: 57px;

        &--pop-up {
            font-size: 24px;
        }

        &--accent {
            font-size: 48px;
            line-height: 57px;
            text-align: left;
            width: 534px;
        }

        &--benefits {
            font-size: 18px;
            line-height: 26px;
        }

        &--card {
            font-size: 16px;
            line-height: 24px;
        }

        &--rates {
            display: block;
        }
    }
}